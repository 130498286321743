<template>
  <div>
    <b-container>
      <div class="header-top-right-content-mobile">
        <div class="content">
          <cmsBlockSimple
            v-if="typeof headerTopRightBlock == 'object'"
            :identifier="headerTopRightBlock.identifier"
          />
        </div>
      </div>
    </b-container>
    <section class="home-banner-section">
      <b-container>
        <b-row class="home-banner-row">
          <b-col cols="12" lg="4" md="12" sm="12" class="home-blog-list-wrap">
            <div class="home-blog-list">
              <BlogListComponent />
            </div>
          </b-col>
          <b-col cols="12" lg="8" md="12" sm="12" class="home-banner-wrap">
            <div class="home-banner">
              <HomeRight />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <div>
      <!-- <div class="container-fluid custom-container-fluid"> -->
      <div class="hero-section">
        <div class="wrapper">
          <div class="container-fluid">
            <div class="custom-row-hero">
              <div class="hero-item-left">
                <div class="homeblog-wrap"></div>
                <div class="hero-slider-mobile show-mobile">
                  <InformativeLine
                    class="mobile-informative"
                    :initialSlideMobile="1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>
<script>
import adobeStock from "@/esf_utrecht_bankxl/assets/images/adobe-stock1.png";

import listIcon from "@/esf_utrecht_bankxl/assets/images/chevron.png";
import BlogListComponent from "@/esf_utrecht_bankxl/core/components/home/BlogListComponent";
import HomeRight from "@/esf_utrecht_bankxl/core/components/home/HomeRight.vue";
import InformativeLine from "@/esf_utrecht_bankxl/core/components/core/InformativeLine";
import cmsBlockSimple from "@/esf_utrecht_bankxl/core/components/core/BlockSimple";
export default {
  name: "Home",
  components: {
    BlogListComponent,
    HomeRight,
    cmsBlockSimple,
    InformativeLine,
  },
  data() {
    return {
      adobeStock,
      listIcon,
    };
  },
  computed: {
    headerTopRightBlock() {
      return this.$store.getters[`cmsBlock/getCmsBlockByIdentifier`](
        "header_top_right_block"
      );
    },
  },
};
</script>